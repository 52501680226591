<template>
  <div class="simulator-results">
    <SimulatorAmounts
      v-for="item in content.items"
      :key="item.title"
      :content="item"
      class="simulator-results__item"
    />

    <div class="simulator-results__footer">
      <p class="simulator-results__footer__content">
        <strong>
          {{ content.footer.title }}
        </strong>
        <small>{{ content.footer.text }}</small>

        <a :href="content.footer.detailsLink.url" @click="onDetailsClick">{{
          content.footer.detailsLink.label || content.footer.detailsLink.title
        }}</a>
      </p>

      <Action
        :content="{
          ...content.footer.orderLink,
          modifiers: ['btn'],
        }"
        class="simulator-results__footer__action"
        @click="onOrderClick"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SimulatorAmounts from '@/components/simulator/Amounts.vue'
import { push } from '@/utils/tracking'

import type { PropType } from 'vue'
import type { Amounts } from '@/components/simulator/Amounts.vue'
import type { Link } from '@/types'

interface Results {
  items: Amounts[]
  footer: {
    title: string
    text: string
    orderLink: Link
    detailsLink: Link
  }
}

defineProps({
  content: {
    type: Object as PropType<Results>,
    required: true,
  },
})

// Emit
const emit = defineEmits<{
  'details-show': []
}>()

// Tell parent to show details
const onDetailsClick = (e: MouseEvent) => {
  e.preventDefault()
  emit('details-show')

  // Track click
  push({
    event: 'simulator',
    eventCategory: 'simulator',
    eventAction: 'click',
    eventLabel: 'detail',
  })
}

// Track click on order CTA
const onOrderClick = () =>
  push({
    event: 'simulator',
    eventCategory: 'simulator',
    eventAction: 'click',
    eventLabel: 'order',
  })
</script>

<style lang="scss" scoped>
$bp-flex: l;
$bp-large: wrapper;

.simulator-results {
  margin-top: $spacing;
  border: 0.1rem solid var(--c-accent);
  border-radius: 1.5rem;

  @include mq($bp-flex) {
    display: flex;
    flex-wrap: wrap;
    width: col(10.5, var(--available-columns));
    margin: 0 0 0 col(0.5, var(--available-columns));
  }

  @include mq($bp-large) {
    flex: 1;
  }
}

.simulator-results__item {
  :deep(.simulator-amounts__item__label) {
    @extend %small;
  }

  & + & {
    border-top: 0.1rem solid var(--c-accent-20);
  }

  @include mq($bp-flex) {
    flex: 1;

    & + & {
      border-top: 0;
    }

    :deep(.simulator-amounts__list) {
      padding-bottom: 0;
    }
  }
}

.simulator-results__footer {
  padding: $spacing;
  background: $c-secondary;
  border-top: 0.1rem solid var(--c-accent-20);
  border-radius: 0 0 1.5rem 1.5rem;

  .simulator-eco & {
    background: var(--c-accent-20);
  }

  @include mq($bp-flex) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 0 col(1, 12);
    width: 100%;
    padding: $spacing * 1.5 col(0.5, 10.5);
  }
}

.simulator-results__footer__content {
  strong,
  a {
    display: block;
  }

  a {
    margin-top: $spacing * 0.5;
    color: var(--c-accent);
    text-decoration: underline;
    transition: color 0.1s;

    &:hover,
    &:focus-visible {
      color: var(--c-accent-40);
    }
  }

  .simulator-eco & a:hover,
  .simulator-eco & a:focus-visible {
    color: var(--c-text);
  }
}

.simulator-results__footer__action {
  margin-top: $spacing;
  white-space: nowrap;
}
</style>
