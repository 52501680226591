<template>
  <div class="simulator-eco">
    <h1
      class="simulator__title p-xxxl wrapper"
      v-html="content.intro_title"
    ></h1>

    <div class="simulator__container wrapper pt-l pb-xxxl">
      <h2 class="simulator__container__title h3">
        {{ content.configurator.title }}
      </h2>

      <SimulatorConfigurator
        :content="{
          items: [
            {
              ...store.values.employeeNumber,
              label: content.configurator.employeeNumber,
              value: store.employeeNumber,
              name: 'employeeNumber',
            },
            {
              ...store.values.faceValue,
              label: content.configurator.faceValue,
              value: store.faceValue,
              name: 'faceValue',
            },
          ],
        }"
        class="simulator__container__configurator bg-green"
      />

      <SimulatorKeyNumbers
        :content="{
          title: content.keyNumbers.title,
          items: [
            {
              ...content.keyNumbers.co2,
              value: Math.round(store.co2PerMonth),
            },
            {
              ...content.keyNumbers.car,
              value: Math.round(store.co2Car),
            },
            {
              ...content.keyNumbers.bottles,
              value: Math.round(store.co2Bottles),
            },
          ],
        }"
        class="simulator__container__key-numbers bg-green"
      />

      <SimulatorResults
        :content="{
          items: [
            {
              title: content.results.title,
              items: [
                {
                  label: content.results.ecoAmount,
                  value: formatPrice(store.faceValue),
                },
                {
                  label: content.results.salaryEquivalent,
                  value: formatPrice(store.employerONSS),
                },
                {
                  label: content.results.salarySaving,
                  value: formatPrice(Math.abs(store.savings)),
                },
              ],
            },
          ],
          footer: {
            ...content.results.footer,
            text: content.results.footer.text.replace(
              '{VALUE}',
              `${formatPercent(store.savingsPercent)}`
            ),
            orderLink: content.order_link,
          },
        }"
        class="simulator__container__results bg-green"
        @details-show="onDetailsShow"
      />
    </div>

    <Transition v-show="showDetails" name="g-fade" @enter="onDetailsEnter">
      <SimulatorDetails
        :content="{
          ...content.details,
          items: [
            {
              title: content.details.salary.title,
              items: [
                {
                  label: content.details.salary.gross,
                  value: formatPrice(store.employerONSS),
                },
                {
                  label: content.details.salary.employerOnss,
                  value: formatPrice(store.employerONSS),
                },
                {
                  label: content.details.salary.taxperworker,
                  value: formatPrice(store.taxPerWorker),
                },
                {
                  label: content.details.salary.companyTax,
                  value: formatPrice(store.companyTax),
                },
              ],
              footer: {
                label: content.details.salary.total,
                value: formatPrice(store.employerONSS),
              },
            },
            {
              title: content.details.eco.title,
              items: [
                {
                  label: content.details.eco.faceValue,
                  value: formatPrice(store.faceValue),
                },

                {
                  label: content.details.eco.gross,
                  value: formatPrice(store.faceValue * store.employeeNumber),
                },
              ],
              footer: {
                label: content.details.eco.total,
                value: formatPrice(store.netTotal),
              },
            },
          ],
          footer: {
            ...content.details.footer,
            text: content.details.footer.text.replace(
              '{VALUE}',
              `${formatPercent(store.savingsPercent)}`
            ),
            savings: formatPrice(store.savings).toString(),
          },
          contactLink: content.contact_link,
          orderLink: content.order_link,
        }"
        class="bg-green"
        @hide="onDetailsHide"
      />
    </Transition>

    <Tips v-if="content.tips" :content="content.tips" />
    <RibbonCta v-if="content.ctaRibbon" :content="content.ctaRibbon" />
  </div>
</template>

<script setup lang="ts">
import { provide, ref } from 'vue'
import { useRoute } from 'vue-router'

import RibbonCta from '@/components/cta/Ribbon.vue'
import SimulatorConfigurator from '@/components/simulator/Configurator.vue'
import SimulatorDetails from '@/components/simulator/Details.vue'
import SimulatorKeyNumbers from '@/components/simulator/KeyNumbers.vue'
import SimulatorResults from '@/components/simulator/Results.vue'
import Tips from '@/components/ui/Tips.vue'
import { useSimulatorEcoStore } from '@/stores/simulator-eco'
import { formatPercent, formatPrice } from '@/utils/format'
import { prefersReducedMotion } from '@/utils/motion'

import type { PropType } from 'vue'
import type { RibbonCta as RibbonCtaType } from '@/components/cta/Ribbon.vue'
import type { KeyNumber } from '@/components/simulator/KeyNumbers.vue'
import type { Tips as TipsType } from '@/components/ui/Tips.vue'
import type { Link } from '@/types'

export interface SimulatorPage {
  // Simulator content
  configurator: Record<string, string>
  keyNumbers: {
    title: string
    co2: KeyNumber
    car: KeyNumber
    bottles: KeyNumber
  }
  results: {
    title: string
    ecoAmount: string
    salaryEquivalent: string
    salarySaving: string
    footer: {
      detailsLink: Link
      title: string
      text: string
    }
  }
  details: {
    title: string
    subtitle: string
    salary: {
      title: string
      gross: string
      employerOnss: string
      taxperworker: string
      companyTax: string
      total: string
    }
    eco: {
      title: string
      faceValue: string
      gross: string
      total: string
    }
    footer: {
      title: string
      text: string
      smallText: string
    }
  }
  // Page content
  tips: TipsType
  ctaRibbon?: RibbonCtaType
  intro_title: string
  order_link: Link
  contact_link: Link
  other_solution_cta: unknown
}

defineProps({
  content: {
    type: Object as PropType<SimulatorPage>,
    required: true,
  },
})

const showDetails = ref(false)
const route = useRoute()
const store = useSimulatorEcoStore()
provide('store', store)

// Update simulator input with query value
// Query is filled when using the duo simulator
if (typeof route.query?.employeeNumber === 'string') {
  // Add value of query param to store
  store.employeeNumber = parseInt(route.query.employeeNumber, 10)
}

// Show details
const onDetailsShow = () => (showDetails.value = true)

// Scroll to details
const onDetailsEnter = (el: Element) =>
  el.scrollIntoView({ behavior: prefersReducedMotion() ? 'instant' : 'smooth' })

const onDetailsHide = () => (showDetails.value = false)
</script>

<style lang="scss" scoped>
$bp-flex: l;
$bp-large: wrapper;

.simulator__title {
  :deep(p) {
    @include section-title;

    &::after {
      margin-left: 0.2em;
    }

    &:not(:first-child) {
      &::before {
        content: none;
      }
    }

    &:not(:last-child) {
      &::after {
        content: none;
      }
    }
  }
}

.simulator__container {
  @include mq($bp-flex) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: col(7, var(--available-columns)) 1fr;
    align-items: flex-start;
    gap: $spacing * 2;
  }

  @include mq($bp-large) {
    grid-template-columns: col(5, var(--available-columns)) 1fr;
  }
}

.simulator__container__title {
  @extend %fw-bold;

  width: 100%;

  @include mq($bp-flex) {
    grid-column: 1/3;
  }
}

.simulator__container__configurator {
  @include mq($bp-flex) {
    grid-row: 2/4;
    grid-column: 1/2;
  }
}

.simulator__container__key-numbers,
.simulator__container__results {
  @include mq($bp-flex) {
    grid-column: 2/3;
    width: 100%;
    margin: 0;
  }
}
</style>
