<template>
  <div class="simulator">
    <h1
      class="simulator__title p-xxxl wrapper"
      v-html="content.intro_title"
    ></h1>

    <div class="simulator__container wrapper pt-l pb-xxxl bg-cobalt">
      <h2 class="simulator__container__title h3 mb-l">
        {{ t('simulateur.calculez-votre-economie') }}
      </h2>

      <SimulatorConfigurator
        :content="{
          items: [
            {
              ...store.values.employeeNumber,
              label: t('simulateur.nombre-d-employes'),
              value: store.employeeNumber,
              name: 'employeeNumber',
            },
            {
              ...store.values.faceValue,
              label: t('simulateur.valeur-faciale'),
              value: store.faceValue,
              name: 'faceValue',
            },
          ],
        }"
        class="simulator__container__configurator"
      />

      <SimulatorResults
        :content="{
          items: [
            {
              title: t('simulateur.employeur'),
              subtitle: t('simulateur.cout-mensuel-brut'),
              items: [
                {
                  label: t('simulateur.en-salaire'),
                  value: formatPrice(store.netAugmentation),
                },
                {
                  label: t('simulateur.avec-ticket-restaurant'),
                  value: formatPrice(store.mealTicketCost),
                },
                {
                  label: t('simulateur.economie-mensuel'),
                  value: formatPrice(store.netMonthlySavings),
                },
              ],
            },
            {
              title: t('simulateur.beneficiaire'),
              subtitle: t('simulateur.montant-attribue'),
              items: [
                {
                  label: t('simulateur.valeur-brut'),
                  value: formatPrice(store.salary),
                },
                {
                  label: t('simulateur.quote-part'),
                  value: formatPrice(store.quotePart),
                },
                {
                  label: t('simulateur.montant-net'),
                  value: formatPrice(store.netPerWorker),
                },
              ],
            },
          ],
          footer: {
            title: `${t('simulateur.soit')} ${formatPercent(store.netMonthlySavingsPercent)}
              ${t('simulateur.moins-cher')}`,
            text: t('simulateur.par-rapport-à-une-augmentation-de-salaire'),
            orderLink: content.order_link,
            detailsLink: {
              label: t('simulateur.details-du-calcul'),
              url: '#details',
            },
          },
        }"
        @details-show="onDetailsShow"
      />
    </div>

    <Transition v-show="showDetails" name="g-fade" @enter="onDetailsEnter">
      <SimulatorDetails
        :content="{
          title: t('simulateur.details-du-calcul'),
          subtitle: `${t('simulateur.comparaison-du-cout-mensuel-brut-pour-l-employeur')}`,
          condition: t(
            'simulateur.sur-base-de-20-cheques-repas-par-mois-et-une-intervention-de-l-employe-de-1-09€'
          ),
          items: [
            {
              title: t('simulateur-details.en-salaire'),
              items: [
                {
                  label: t('simulateur-details.pouvoir-d-achat'),
                  text: t('simulateur-details.pour-le-beneficiaire'),
                  value: formatPrice(store.netPerWorker),
                },
                {
                  label: t('simulateur-details.valeur-faciale'),
                  text: t(
                    'simulateur-details.sur-base-d-un-taux-marginal-IPP-45%-et-du-taux-ONSS-personnel-de-13,07%'
                  ),
                  value: formatPrice(store.grossPerWorker),
                },
                {
                  label: t('simulateur-details.taux-ONSS-patronale'),
                  text: t('simulateur-details.pour-le-beneficiaire'),
                  value: formatPrice(store.onssPerWorker),
                },
                {
                  label: t('simulateur-details.cout-patronal-mensuel'),
                  text: t('simulateur-details.par-beneficiaire'),
                  value: formatPrice(store.netAugmentationPerWorker),
                },
              ],
              footer: {
                label: t('simulateur-details.cout-mensuel'),
                value: formatPrice(store.netAugmentation),
              },
            },
            {
              title: `${t('simulateur-details.avec-tickets-restaurant®')}®*`,
              items: [
                {
                  label: t('simulateur-details.pouvoir-d-achat'),
                  text: t('simulateur-details.pour-le-beneficiaire'),
                  value: formatPrice(store.netPerWorker),
                },

                {
                  label: t('simulateur-details.depense-non-admise'),
                  text: t(
                    'simulateur-details.sur-base-d-un-taux-ISOC-de-25%-2€-deductibles-par-titre'
                  ),
                  value: formatPrice(store.dna),
                },
                {
                  label: t('simulateur-details.cout-patronal-mensuel'),
                  text: t('simulateur-details.par-beneficiaire'),
                  value: formatPrice(store.mealTicketCostPerWorker),
                },
              ],
              footer: {
                label: t('simulateur-details.cout-mensuel'),
                value: formatPrice(store.mealTicketCost),
              },
            },
          ],
          footer: {
            title: t('simulateur.economie-mensuel'),
            text: `${t('simulateur.soit')} ${formatPercent(store.netMonthlySavingsPercent)}
              ${t('simulateur.moins-cher')}`,
            smallText: t(
              'simulateur.par-rapport-à-une-augmentation-de-salaire'
            ),
            savings: formatPrice(store.netMonthlySavings).toString(),
          },
          contactLink: content.contact_link,
          orderLink: content.order_link,
        }"
        @hide="onDetailsHide"
      />
    </Transition>

    <Tips v-if="content.tips" :content="content.tips" />
    <RibbonCta v-if="content.ctaRibbon" :content="content.ctaRibbon" />
  </div>
</template>

<script setup lang="ts">
import { provide, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import RibbonCta from '@/components/cta/Ribbon.vue'
import SimulatorConfigurator from '@/components/simulator/Configurator.vue'
import SimulatorDetails from '@/components/simulator/Details.vue'
import SimulatorResults from '@/components/simulator/Results.vue'
import Tips from '@/components/ui/Tips.vue'
import { useSimulatorStore } from '@/stores/simulator'
import { formatPercent, formatPrice } from '@/utils/format'
import { prefersReducedMotion } from '@/utils/motion'

import type { PropType } from 'vue'
import type { RibbonCta as RibbonCtaType } from '@/components/cta/Ribbon.vue'
import type { Tips as TipsType } from '@/components/ui/Tips.vue'
import type { Link } from '@/types'

export interface SimulatorPage {
  tips: TipsType
  ctaRibbon?: RibbonCtaType
  intro_title: string
  order_link: Link
  contact_link: Link
  // TODO
  other_solution_cta: unknown
}

defineProps({
  content: {
    type: Object as PropType<SimulatorPage>,
    required: true,
  },
})

const showDetails = ref(false)
const route = useRoute()
const store = useSimulatorStore()
const { t } = useI18n()

provide('store', store)

// Update simulator input with query value
// Query is filled when using the duo simulator
if (typeof route.query?.employeeNumber === 'string') {
  // Add value of query param to store
  store.employeeNumber = parseInt(route.query.employeeNumber, 10)
}

// Show details
const onDetailsShow = () => (showDetails.value = true)

// Scroll to details
const onDetailsEnter = (el: Element) =>
  el.scrollIntoView({ behavior: prefersReducedMotion() ? 'instant' : 'smooth' })

const onDetailsHide = () => (showDetails.value = false)
</script>

<style lang="scss" scoped>
$bp-flex: l;
$bp-large: wrapper;

.simulator__title {
  :deep(p) {
    @include section-title;

    &::after {
      margin-left: 0.2em;
    }

    &:not(:first-child) {
      &::before {
        content: none;
      }
    }

    &:not(:last-child) {
      &::after {
        content: none;
      }
    }
  }
}

.simulator__container {
  @include mq($bp-flex) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.simulator__container__title {
  @extend %fw-bold;

  width: 100%;
}

.simulator__container__configurator {
  @include mq($bp-flex) {
    width: col(7, var(--available-columns));
  }

  @include mq($bp-large) {
    width: col(5, var(--available-columns));
  }
}
</style>
