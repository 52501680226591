<template>
  <div class="simulator-configurator bg-cobalt">
    <SimulatorStepper
      v-for="item in content.items"
      :key="item.label"
      :content="item"
      class="simulator-configurator__item"
      @input="onStepperInput(item.name, $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue'

import SimulatorStepper from '@/components/simulator/Stepper.vue'

import type { PropType } from 'vue'
import type { Stepper } from '@/components/simulator/Stepper.vue'

interface Configurator {
  items: Stepper[]
}

defineProps({
  content: {
    type: Object as PropType<Configurator>,
    required: true,
  },
})

const store = inject('store') as Record<string, unknown>

// Update value in store
const onStepperInput = (name: string, value: number) => {
  if (store?.[name]) {
    store[name] = value
  }
}
</script>

<style lang="scss" scoped>
$bp-flex: l;
$bp-large: wrapper;

.simulator-configurator {
  padding: $spacing * 1.5 $spacing * 0.25;
  background: var(--c-accent-40);
  border: 0.1rem solid var(--c-accent);
  border-radius: 2rem;

  @include mq(xs) {
    padding: $spacing * 1.5;
  }

  @include mq($bp-flex) {
    padding: $spacing * 3 col(0.5, var(--available-columns));
  }

  @include mq($bp-large) {
    padding: $spacing * 4 col(1, var(--available-columns));
  }
}

.simulator-configurator__item {
  & + & {
    margin-top: $spacing;
  }

  @include mq($bp-flex) {
    & + & {
      margin-top: $spacing * 3;
    }
  }
}
</style>
