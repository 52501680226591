import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import { countriesAvailable } from '@/config/countries'
import { useCountry } from '@/plugins/country'

import type { Language } from '@/types'

export interface SimulatorInput {
  label: string
  min?: number
  max?: number
  value: number
  step: number
  showMinMax?: boolean
}

interface SimulatorValues {
  faceValue: SimulatorInput
  employeeNumber: SimulatorInput
  daysPerMonth: number
  employerONSS: number
  employeeONSS: number
  incomeTax: number
  isoc: number
  co2: Record<string, number>
}

const simulatorValues: Record<
  (typeof countriesAvailable)[number],
  SimulatorValues
> = {
  be: {
    faceValue: {
      label: 'simulateur.valeur-faciale',
      value: 250,
      min: 10,
      max: 250,
      step: 10,
      showMinMax: true,
    },
    employeeNumber: {
      label: 'simulateur.nombre-d-employes',
      value: 1,
      min: 0,
      step: 1,
    },
    // Taxes
    daysPerMonth: 20,
    employerONSS: 0.25,
    employeeONSS: 0.1307,
    incomeTax: 0.45, // IPP
    isoc: 0.29,
    // CO2 helpers
    co2: {
      ratio: 1, // 100%
      nl: 15, // BXL-Antwerp
      fr: 19, // BXL-Namur
      plasticBottles: 0.102,
    },
  },
  lu: {
    faceValue: {
      label: 'simulateur.valeur-faciale',
      value: 250,
      min: 10,
      max: 250,
      step: 10,
      showMinMax: true,
    },
    employeeNumber: {
      label: 'simulateur.nombre-d-employes',
      value: 1,
      min: 0,
      step: 1,
    },
    // Taxes
    daysPerMonth: 20,
    employerONSS: 0.25,
    employeeONSS: 0.1307,
    incomeTax: 0.45, // IPP
    isoc: 0.29,
    // CO2 helpers
    co2: {
      ratio: 1, // 100%
      nl: 15, // BXL-Antwerp
      fr: 19, // BXL-Namur
      plasticBottles: 0.102,
    },
  },
}

export const useSimulatorEcoStore = defineStore('simulator-eco', () => {
  const country = useCountry()
  const route = useRoute()
  const { lang } = route.params
  const values = simulatorValues[country]
  const employeeNumber = ref(values.employeeNumber.value)
  const faceValue = ref(values.faceValue.value)

  // CO2
  const co2PerMonth = computed(
    () => employeeNumber.value * faceValue.value * values.co2.ratio
  )
  const co2Car = computed(
    () => co2PerMonth.value / values.co2[lang as Language]
  )
  const co2Bottles = computed(
    () => co2PerMonth.value / values.co2.plasticBottles
  )

  // Savings
  const netPerWorker = computed(() => faceValue.value * employeeNumber.value)
  const incomeTax = computed(
    // eslint-disable-next-line no-mixed-operators
    () => netPerWorker.value * values.incomeTax + netPerWorker.value
  )
  const taxPerWorker = computed(
    // eslint-disable-next-line no-mixed-operators
    () => incomeTax.value + incomeTax.value * values.employeeONSS
  )
  const employerONSS = computed(
    // eslint-disable-next-line no-mixed-operators
    () => taxPerWorker.value * values.employerONSS + taxPerWorker.value
  )
  const companyTax = computed(
    () => (incomeTax.value + employerONSS.value) * values.isoc
  )
  const netTotal = computed(() => Number(netPerWorker.value))
  const savings = computed(() => employerONSS.value - netTotal.value)
  const savingsPercent = computed(() => savings.value / employerONSS.value)

  return {
    values,
    employeeNumber,
    faceValue,
    netPerWorker,
    companyTax,
    taxPerWorker,
    employerONSS,
    netTotal,
    savings,
    savingsPercent,
    co2PerMonth,
    co2Car,
    co2Bottles,
  }
})
