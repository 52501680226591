import { useI18n } from 'vue-i18n'

// Format price as 00,00 € or € 00,00 for nl/en
function formatPrice(value?: number) {
  // Value could be undefined due to user input
  if (typeof value !== 'number') {
    return 0
  }

  const { locale } = useI18n()
  const price = value.toFixed(2).replace('.', ',')

  if (typeof locale === 'string' && ['nl', 'en'].includes(locale)) {
    return `€ ${price}`
  }

  return `${price} €`
}

// Format decimal number with percent sign
function formatPercent(value?: number) {
  // Value could be undefined due to user input
  if (typeof value !== 'number') {
    return 0
  }

  return `${(value * 100).toFixed(2).replace('.', ',')}%`
}

export { formatPrice, formatPercent }
