<template>
  <div class="simulator-stepper">
    <label class="simulator-stepper__label" for="face_value">
      {{ content.label }}
    </label>

    <div class="simulator-stepper__input-outer">
      <div class="simulator-stepper__controls">
        <button
          v-if="controls"
          class="simulator-stepper__controls__button--minus"
          :disabled="model <= minimum"
          @click="onDecrement"
        >
          <span>Decrease</span>
          <SvgSprite class="icon" symbol="ui-minus" size="18" role="img" />
        </button>
        <button
          v-if="controls"
          class="simulator-stepper__controls__button--plus"
          :disabled="content.max !== undefined && model >= content.max"
          @click="onIncrement"
        >
          <span>Increase</span>
          <SvgSprite class="icon" symbol="ui-plus" size="18" role="img" />
        </button>
      </div>
      <input
        v-model="model"
        :name="content.name"
        class="simulator-stepper__number h3"
        type="number"
        inputmode="numeric"
        :step="content.step"
        :min="content.min"
        :max="content.max"
        @input="onInput"
      />
    </div>

    <div
      v-if="content.showMinMax !== false && content.max"
      class="simulator-stepper__infos"
    >
      <span class="simulator-stepper__infos__min">
        {{ content.min ? formatNumber(content.min) : 0 }}
      </span>

      <span class="simulator-stepper__infos__max">
        {{ formatNumber(content.max) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
export interface Stepper {
  label: string
  min?: number
  max?: number
  value: number
  step: number
  showMinMax?: boolean
  name: string
}
</script>

<script setup lang="ts">
import { ref } from 'vue'

import type { PropType } from 'vue'

const props = defineProps({
  content: {
    type: Object as PropType<Stepper>,
    required: true,
  },
  controls: {
    type: Boolean,
    required: false,
    default: true,
  },
})

// Variables
const model = ref(props.content.value || 0)
const minimum = props.content.min || 0
const step = props.content.step || 1

// Emit
const emit = defineEmits<{
  input: [value: number]
}>()

// Format number with EU convention: 00,00
const formatNumber = (n: number) => Number(n).toFixed(2).replace('.', ',')

// Increment value without exceeding max value
const onIncrement = () => {
  model.value += step

  if (props.content.max && model.value >= props.content.max) {
    model.value = props.content.max
  }

  emit('input', model.value)
}

// Decrement value with minimum value
// TODO: fix 2.0999999 bug
const onDecrement = () => {
  model.value -= step

  if (model.value <= minimum) {
    model.value = minimum
  }

  emit('input', model.value)
}

// Clamp user input between min and max
const onInput = () => {
  if (model.value < minimum) {
    model.value = minimum
  } else if (props.content.max && model.value > props.content.max) {
    model.value = props.content.max
  }
  emit('input', model.value)
}
</script>

<style lang="scss" scoped>
$bp: l;

.simulator-stepper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.simulator-stepper__label {
  @extend %fw-normal;

  margin-bottom: $spacing * 0.25;

  @include mq($bp) {
    margin-bottom: $spacing * 0.5;
  }
}

.simulator-stepper__number {
  @extend %text-center;

  width: 100%;
  padding: $spacing * 0.5;
  background: $c-white;
  border: 0.1rem solid var(--c-accent-10);
  border-radius: 4rem;
  outline: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }

  @include mq($bp) {
    height: calc(4rem + #{$spacing * 1.2});
    padding: $spacing * 0.6;
  }
}

.simulator-stepper__input-outer {
  position: relative;
  width: 100%;
}

.simulator-stepper__controls {
  @include center-y;

  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  pointer-events: none;
}

.simulator-stepper__controls__button,
[class*='simulator-stepper__controls__button--'] {
  @extend %button-nostyle;

  position: relative;
  display: grid;
  place-content: center;
  width: 3.6rem;
  height: 3.6rem;
  margin: $spacing * 0.8;
  color: $c-white;
  background: $c-black;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: initial;
  transition: background-color 0.2s ease-in-out;

  span {
    @extend %visually-hidden;
  }

  .icon {
    width: 1.8rem;
    height: 1.8rem;
    fill: currentcolor;
  }

  &:disabled {
    background: $c-grey-light;
    box-shadow: none;
    cursor: not-allowed;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    background: var(--c-accent);
    box-shadow: 0 0.3rem 0.5rem 0.1rem rgba($c-black, 0.1);
  }

  @include mq($bp) {
    width: 4rem;
    height: 4rem;
    margin: $spacing * 0.6;
  }
}

.simulator-stepper__infos {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5rem;
}

.simulator-stepper__infos__min,
.simulator-stepper__infos__max {
  @extend %fw-medium;
  @extend %text-center;

  width: 6rem;
}
</style>
